import React from 'react';
import { Link } from 'react-router-dom';

const Sidenav = () => {
  return(
    <div className="sidenavWrapper">
      <Link to="/browse" className="sidenavLink">
        <svg className="slIco" viewBox="0 0 28 24" xmlns="http://www.w3.org/2000/svg"><path d="m26.602 23.85-6.066-4.073-6.073 4.073c-.132.091-.295.145-.472.145s-.34-.054-.474-.147l.003.002-6.068-4.073-6.067 4.073c-.132.091-.296.145-.472.145-.168 0-.325-.049-.456-.134l.003.002c-.279-.194-.46-.512-.46-.874 0-.007 0-.014 0-.021v.001-17.556c0-.006 0-.013 0-.02 0-.352.173-.663.439-.853l.003-.002 6.541-4.392c.132-.091.295-.145.472-.145s.34.054.474.147l-.003-.002 6.066 4.073 6.067-4.073c.132-.091.295-.145.472-.145s.34.054.474.147l-.003-.002 6.541 4.392c.266.189.438.497.438.844 0 .013 0 .025-.001.038v-.002 17.558.023c0 .361-.182.679-.459.869l-.004.002c-.127.082-.283.131-.45.131-.175-.004-.337-.059-.472-.152l.003.002zm-24.602-17.794v15.101l4.436-3.086v-15.101zm12.993 15.045 4.56-3.062v-6.823c-.044.044-.08.087-.118.131-.113.131-.225.262-.338.387l-.75-.662c.106-.125.218-.25.331-.381.175-.194.35-.4.538-.606l.331.3v-7.447l-4.561 3.062v7.017c.154-.009.296-.024.435-.047l-.023.003.16.987c-.169.025-.35.044-.531.056h-.031zm-6.56-3.055 4.56 3.055v-8.141l-.262.8c-.494-.163-.921-.37-1.316-.625l.022.013.531-.85c.295.191.633.358.991.483l.034.01v-6.792l-4.56-3.061v6.304c.296.157.545.312.783.483l-.02-.014-.581.812c-.056-.044-.118-.08-.181-.125zm13.813-8.766c-.265.058-.496.135-.716.234l.023-.009v8.575l4.429 3.02v-15.106l-4.436-3.033v5.467c.129-.044.297-.089.468-.125l.032-.006zm-5.735 3.348c.37-.184.689-.405.972-.665l-.003.003.662.75c-.345.314-.738.585-1.164.798l-.03.014zm-6.923-1.124c-.056-.075-.118-.144-.181-.218l.762-.65.187.225c.218.256.418.5.64.72l-.687.725c-.252-.248-.489-.509-.711-.782l-.014-.018zm-6.379.24.013-.05c.136-.487.333-.912.586-1.297l-.011.017.825.56c-.188.295-.34.636-.438 1l-.006.026-.018.056zm20.905-2.044-.64.65-.712-.712.65-.64-.65-.64.712-.712.64.65.64-.65.712.712-.65.64.65.64-.712.712zm-19.617-.17c.206-.195.438-.367.689-.509l.018-.009c.169-.093.368-.18.574-.249l.025-.007.306.95c-.15.044.075.038-.062.113-.334.132-.62.281-.888.456l.019-.012z"/></svg>
        <span className="slSpan">MESOC Cities</span>
      </Link>
      <Link to="/my-documents" className="sidenavLink">
        <svg className="slIco" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg"><path d="m19.638 8.945c0-.228-.07-.439-.19-.613l.002.004c-.019-.027-.039-.054-.06-.08l-.005-.006c-.02-.024-.041-.047-.063-.069l-.01-.01c-.009-.009-.018-.02-.028-.029l-7.822-7.821c-.022-.022-.046-.044-.07-.063l-.022-.02-.054-.04-.027-.019c-.019-.012-.039-.024-.058-.035l-.025-.015c-.027-.014-.053-.027-.081-.039l-.034-.014-.054-.02-.039-.012-.061-.016-.031-.007c-.03-.006-.061-.01-.092-.014h-9.722c-.6 0-1.087.484-1.091 1.083v21.818c0 .603.489 1.091 1.091 1.091h17.454c.603 0 1.091-.489 1.091-1.091v-13.935c0-.009.001-.018.001-.028zm-7.857-5.225 4.13 4.135h-4.13zm-9.599 18.098v-19.637h7.42v6.767c0 .603.489 1.091 1.091 1.091h6.767v11.779z"/><path d="m13.454 17.454h-7.273c-.009 0-.02 0-.031 0-.603 0-1.091.489-1.091 1.091s.489 1.091 1.091 1.091h.032-.002 7.273.031c.603 0 1.091-.489 1.091-1.091s-.489-1.091-1.091-1.091c-.011 0-.021 0-.032 0z"/><path d="m13.454 13.091h-7.273c-.009 0-.02 0-.031 0-.603 0-1.091.489-1.091 1.091s.489 1.091 1.091 1.091h.032-.002 7.273.031c.603 0 1.091-.489 1.091-1.091s-.489-1.091-1.091-1.091c-.011 0-.021 0-.032 0z"/></svg>
        <span className="slSpan">My documents</span>
      </Link>
      <Link to="/upload-document" className="sidenavLink">
        <svg className="slIco" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m0 17.143v6.857h24v-6.857zm5.143 5.143h-3.429v-1.714h3.429z"/><path d="m17.143 5.143h-3.429v8.571h-3.429v-8.571h-3.429l5.143-5.143z"/></svg>
        <span className="slSpan">Upload document</span>
      </Link>
      <Link to="/send-feedback" className="sidenavLink">
      <svg className="slIco" viewBox="0 0 27 24" xmlns="http://www.w3.org/2000/svg"><path d="m24 24h-24v-24h18.4v2.4h-16v19.2h20v-8.8h2.4v11.2zm-19.52-12.42 1.807-1.807 5.422 5.422 13.68-13.68 1.811 1.803-15.491 15.491z"/></svg>
        <span className="slSpan">Send feedback</span>
      </Link>
    </div>
  );
};

export default Sidenav;